import Carousel from "stimulus-carousel";

export default class extends Carousel {
  get defaultOptions() {
    return {
      autoHeight: true,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    };
  }
}
