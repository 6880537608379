import "../styles/dragontail.scss";

import "fslightbox";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);

application.load(definitionsFromContext(context));

window.Stimulus = application;

window.document.addEventListener("DOMContentLoaded", function () {
  let doc_links = document.querySelectorAll('a[href^="/documents/"]');
  Array.from(doc_links, (doc_link) =>
    doc_link.setAttribute("target", "_blank")
  );
});
