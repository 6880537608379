import { Controller } from "@hotwired/stimulus";

let scroll_threshold = 20;

export default class extends Controller {
  updateDisplay() {
    if (document.documentElement.scrollTop > scroll_threshold) {
      this.element.classList.remove("hidden");
    } else {
      this.element.classList.add("hidden");
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
